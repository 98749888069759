import React from 'react'
import styles from './_hero.module.sass'
import heroImg from '../../../public/images/profile/profile1.webp'
import Image from 'next/image'

const Hero = React.memo(() => {
    
    return (
      <div className={styles.hero}>
        <article id="heroArticle" className={styles.heroArticle}>
          <h1>
            <span className={styles.introSentence}>
              My Name Is&nbsp;
              <div className={styles.gradientText}>Anmol Virk</div>.
            </span>
            <span className={styles.headingSentence}>
              I'm a Full Stack Developer based in Brisbane, Australia.
            </span>
          </h1>
          <h2>
            <span className={`${styles.headingSection} ${styles.headingIntro}`}>
              <span>I am passionate about building digital products that are</span>
            </span>
            <span className={styles.headingTraits}>
              <span className={styles.headingSection}>
                <div className={styles.emoji}>🌎</div>
                <span>highly accessible,</span>
              </span>
              <span className={styles.headingSection}>
                <div className={styles.emoji}>🖍️</div>
                <span>UI/UX focused</span>
              </span>
              <span className={styles.headingSection}>
                <div className={styles.emoji}>🧭</div>
                <span>SEO-driven,</span>
              </span>
              <span className={styles.headingSection}>
                <div className={styles.emoji}>🚀</div>
                <span>extremely performant,</span>
              </span>
              <span className={styles.headingSection}>
                <div className={styles.emoji}>📐</div>
                <span>and fully responsive.</span>
              </span>
            </span>
          </h2>
        </article>
        <div className={styles.profileImg}>
          <Image
            placeholder="blur"
            alt="Profile Image"
            src={heroImg}
            className={styles.pImgs}
            sizes="(max-width: 600px) 45vw, (max-width: 875px) 30vw, (max-width: 1200px) 25vw, 20vw"
            priority
            fill
          />
        </div>
      </div>
    )
})

export default Hero