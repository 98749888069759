import React, {useState, useEffect} from "react";
import styles from "./_header.module.sass";
import Image from "next/image";
import Link from "next/link";
import iconImg from '../../public/images/icons/icon.webp'

const Header = React.memo(({links}) => {

  const [copied, setCopied] = useState()

  const copy = (e) => {
    e.preventDefault()
    if(!copied){
      navigator.clipboard.writeText('theanmolvirk@gmail.com').then(()=>{
        setCopied(true)
        setTimeout(()=>{
          setCopied(false)
        }, 1000)
      })
    }
  }

  let sections = []

  const scroll = (e, index) => {
    e.preventDefault()
    window.scrollTo(0, sections[index].offsetTop - 12/100*window.innerHeight)
  }

  useEffect(()=>{
    if(window.innerWidth >= 1200){
      sections = document.getElementsByTagName('section')
    }
  }, [])

  const scrollToTop = (e) => {
    e.preventDefault()
    window.scrollTo({top: 0})
  }

  return (
    <header className={styles.header}>
        <div className={styles.headerLinks}>
          <Link shallow={true} prefetch={false} className={styles.homeBtn} onMouseUp={scrollToTop} href={"/"}>
              <Image src={iconImg} placeholder="blur" sizes="(max-width: 600px) 10vw, 40px" alt="Go back to home" fill="true" priority />
          </Link>
          <div className={styles.links}>
            {links?links.map((item, i) => (
              <div className={styles.link} key={i} onMouseDown={(e)=>scroll(e, i)} id={'link'+i} >{item}</div>
            )):null}
            <hr className={styles.underline} id='underline' />
          </div>
        </div>
        <div className={styles.emailBar}>
          <a href="mailto:theanmolvirk@gmail.com">
            <span>theanmolvirk@gmail.com</span>
          </a>
          <button id="copyBtn" onMouseDown={copy} className={copied?styles.copied:''}>{copied?'Copied':'Copy Email'}</button>
        </div>
    </header>
  );
})

export default Header