import React from 'react'
import styles from './_projects.module.sass'

import wordbankImg from '../../../public/images/wordbank/2.webp'
import uplyftImg from '../../../public/images/uplyft/1.webp'
import pencylImg from '../../../public/images/pencyl/5.webp'

import Link from 'next/link'
import Image from 'next/image'

const projectCards = [
    {
        name: 'Wordbank',
        img: wordbankImg,
        about: 'A mobile-first next.js app that helps language learners improve their vocabulary with personalized exercises, progress tracking, and custom word collections.',
        link: '/projects/wordbank',
        priority: true
    },
    {
        name: 'Uplyft',
        img: uplyftImg,
        about: 'Designed and developed a complete SaaS project from beginning to end. With all the features required to build a SaaS Startup.',
        link: '/projects/uplyft',
        priority: true
    },
    {
        name: 'Pencyl',
        img: pencylImg,
        about: 'Designed and developed a platform for creating and generating NFTs from beginning to end.',
        link: '/projects/pencyl',
        priority: true
    }
]

const Projects = React.memo(() => {

    const Project = React.memo(({name, img, about, link, priority}) => {
        return (
            <li>
                <Link href={link} shallow={true} prefetch={false}>
                <div className={styles.imageBack}>
                    <div className={styles.projectImg}><Image src={img} alt={name} fill="true" priority={priority} placeholder="blur" sizes="(max-width: 600px) 70vw, (max-width: 1200px) 40vw, 30vw" /></div>
                </div>
                <article>
                    <div className={styles.projectTitle}>{name}</div>
                    <div className={styles.summary}>
                    <p>{about}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                    </svg>
                    </div>
                </article>
                </Link>
            </li>
        )
    })
  
    return (
    <section className={styles.projects}>
        <div className={styles.sectionTitles}>My Work</div>
        <ul>
        {projectCards.map((i, key)=>{
            return (
                <Project key={key} {...i} />
            )
        })}
        </ul>
    </section>
    )
})

export default Projects