import React from 'react'
import styles from './_skills.module.sass'
import skillImg from '../../../public/images/profile/profile3.webp'
import Image from 'next/image'

const categories = [
    {
        name: 'Programming Language',
        color: '#FF004D',
        skills: [
            {name: 'TypeScript', learning: false}
        ]
    },
    {
        name: 'Frontend',
        color: '#2192FF',
        skills: [
            {name: 'Next.js', learning: false, about: 'Fullstack Framework'},
            {name: 'Qwik', learning: true, about: 'Frontend Library'}
        ]
    },
    {
        name: 'Mobile',
        color: '#F72798',
        skills: [
            {name: 'React Native', learning: false, about: 'Cross Platform Development'},
            {name: 'Flutter', learning: true, about: 'Cross Platform Development'}
        ]
    },
    {
        name: 'E-commerce',
        color: '#38E54D',
        skills: [
            {name: 'Hydrogen', learning: true, about: `Custom Shopify Storefront`},
            {name: 'Medusa', learning: true, about: `E-commerce Platform`}
        ]
    },
    {
        name: 'Database',
        color: '#8576FF',
        skills: [
            {name: 'TiDB', learning: false, about: 'NewSQL Database'},
            {name: 'MongoDB', learning: true, about: `NoSQL Database`}
        ]
    },
    {
        name: 'Backend',
        color: '#4649FF',
        skills: [
            {name: 'Nest JS', learning: true, about: 'Node.js Framework'}
        ]
    },
    {
        name: 'Cloud',
        color: '#FFAF45',
        skills: [
            {name: 'AWS', learning: true}
        ]
    },
    {
        name: 'Dev Ops',
        color: '#577B8D',
        skills: [
            {name: 'Docker', learning: true, about: 'Container Applications'},
            {name: 'Kubernetes', learning: true, about: 'Container Orchestration'},
        ]
    }
]

const Skills = React.memo(() => {
    return (
      <section className={styles.skills}>
        <ul>
          <li>
            <div className={styles.sectionTitles}>My Skills</div>
            <div className={styles.skillImg}>
              <Image loading="lazy" src={skillImg} placeholder="blur" sizes="(max-width: 600px) 35vw, (max-width: 760px) 30vw, (max-width: 1086px) 25vw, (max-width: 1336px) 20vw, (max-width: 1556px) 15vw, 10vw" alt="Profile Image" className={styles.pImgs} fill="true" />
            </div>
          </li>
          {categories.map((category, key)=>{
            const categoryColors = () => {
              const hex = category.color.replace('#', '');
              const r = parseInt(hex.substring(0, 2), 16);
              const g = parseInt(hex.substring(2, 4), 16);
              const b = parseInt(hex.substring(4, 6), 16);
              const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
              return {
                color: (yiq >= 128) ? 'black' : 'white',
                fontWeight: (yiq >= 128) ? 600 : 400
              };
            }
            return category.skills.map((skill, key2)=>
              <li key={`${key}${key2}`} className={skill.learning?styles.learningBlock:styles.skillBlock}>
                <div className={styles.skillContent}>
                  <article>
                    <h3>{skill.name}</h3>
                    <p>{skill.about}</p>
                  </article>
                  {skill.learning?<div className={styles.learningTag}>( Learning )</div>:null}
                </div>
                {key2 === 0  && key2 === category.skills.length-1 && <div style={{backgroundColor: category.color, color: categoryColors().color, fontWeight: categoryColors().fontWeight}} className={styles.categorySingle}>{category.name}</div>}
                {key2 === 0  && key2 !== category.skills.length-1 && <div style={{backgroundColor: category.color, color: categoryColors().color, fontWeight: categoryColors().fontWeight}} className={styles.category}>{category.name}</div>}
                {key2 > 0 && key2 < category.skills.length-1 && <div style={{backgroundColor: category.color}} className={styles.categoryMiddle}></div>}
                {key2 !== 0 && key2 === category.skills.length-1 && <div style={{backgroundColor: category.color}} className={styles.categoryEnd}></div>}
              </li>
            )
          })}
        </ul>
      </section>
    )
})

export default Skills