import React from 'react'
import styles from './_about.module.sass'
import aboutImg from '../../../public/images/profile/profile2.webp'
import Image from "next/image"

const about = [
  {text: 'High Quality Work', emoji: '💎'},
  {text: 'Curiousity', emoji: '🧠'},
  {text: 'Out of the Box Thinking', emoji: '🧪'},
  {text: 'Creativity', emoji: '🎨'},
  {text: 'Problem-Solving Ability', emoji: '🧩'},
  {text: 'Passion', emoji: '🔥'},
  {text: 'Proactiveness', emoji: '🙋🏽‍♂️'},
  {text: 'Independence', emoji: '🗽'},
  {text: 'Assertiveness', emoji: '🦣'},
  {text: 'Adaptability', emoji: '🏗️'},
  {text: 'Self-awareness', emoji: '🗿'},
  {text: 'Goal Orientation', emoji: '🚩'},
  {text: 'Dedication', emoji: '🪖'},
  {text: 'Ambition', emoji: '🏆️'},
  {text: 'Decisiveness', emoji: '💪🏽'},
  {text: 'Action Orientation', emoji: '🎯'}
]

const About = React.memo(() => {
    return (
      <section className={styles.stars}>
        <div className={styles.rating}>
          <div className={styles.ratingNum}>
            5<span>/5</span>
          </div>
          <div className={styles.five}>⭐️⭐️⭐️⭐️⭐️</div>
          <div className={styles.tagLine}>
            ( Not to brag but... 😉 )
          </div>
          <div className={styles.aboutImg}>
            <Image
              loading="lazy"
              src={aboutImg}
              alt="Profile Image"
              className={`${styles.mirror} ${styles.pImgs}`}
              fill="true"
              placeholder="blur"
              sizes="(max-width: 600px) 85vw, (max-width: 760px) 55vw, (max-width: 1200px) 40vw, 30vw"
            />
          </div>
        </div>
        <ul className={styles.aspects}>
          {about.map((i, k) => {
            return (
              <li key={k}>
                <article>
                  <div className={styles.emoji}>{i.emoji}</div>
                  <p>{i.text}</p>
                </article>
                <div className={styles.five}>⭐️⭐️⭐️⭐️⭐️</div>
              </li>
            )
          })}
        </ul>
      </section>
    )
})

export default About