import React, { useRef, useState } from "react";
import styles from "./_footer.module.sass";
import Image from "next/image";
import footerImg from '../../public/images/profile/profile4.webp'
import resume from '../../public/images/icons/resume.webp'
import github from '../../public/images/icons/github.webp'
import linkedin from '../../public/images/icons/linkedin.webp'

const Footer = React.memo(() => {
  const year = useRef(new Date().getFullYear())
  const [copied, setCopied] = useState()
  const copy = (e) => {
    e.preventDefault()
    if(!copied){
      navigator.clipboard.writeText('theanmolvirk@gmail.com').then(()=>{
        setCopied(true)
        setTimeout(()=>{
          setCopied(false)
        }, 1000)
      })
    }
  }
  return (
     <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerImg}>
          <Image loading="lazy" src={footerImg} alt="Profile Image" className={styles.pImgs} fill="true" placeholder="blur" sizes="(max-width: 600px) 45vw, (max-width: 840px) 35vw, (max-width: 1200px) 25vw, 20vw" />
        </div>
        <div className={styles.content}>
          <div className={styles.tagLine}>What are you waiting for?</div>
          <div className={styles.ctaText}>Let's talk!</div>
          <div className={styles.emailBar}>
            <a href="mailto:theanmolvirk@gmail.com">
              <span>theanmolvirk@gmail.com</span>
            </a>
            <button onMouseDown={copy} id="copyBtn2" className={copied?styles.copied:''}>{copied?'Copied':'Copy Email'}</button>
          </div>
          <ul className={styles.links}>
            <li>
              <a href="/Anmol_Virk_Resume.pdf">
                <span className={styles.icon}>
                  <div className={styles.emoji}>
                    <Image
                      alt="Resume"
                      src={resume}
                      fill="true"
                      placeholder="blur"
                      sizes="(max-width: 600px) 9vw, (max-width: 1200px) 3ch, 2ch"
                      loading="lazy"
                    />
                  </div>
                </span>
                <article>
                  <div className={styles.footerLink}>Resume</div>
                  <p>
                      anmolvirk.com/resume
                  </p>
                </article>
              </a>
            </li>
            <li>
              <a href="https://github.com/anmolvirkk">
                <span className={styles.icon}>
                  <div className={styles.emoji}>
                    <Image
                      alt="Github"
                      src={github}
                      fill="true"
                      placeholder="blur"
                      sizes="(max-width: 600px) 9vw, (max-width: 1200px) 3ch, 2ch"
                      loading="lazy"
                    />
                  </div>
                </span>
                <article>
                  <div className={styles.footerLink}>Github</div>
                  <p>
                      github.com/anmolvirkk
                  </p>
                </article>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/anmolvirkk/">
                <span className={styles.icon}>
                  <div className={styles.emoji}>
                    <Image
                      alt="LinkedIn"
                      src={linkedin}
                      fill="true"
                      placeholder="blur"
                      sizes="(max-width: 600px) 9vw, (max-width: 1200px) 3ch, 2ch"
                      loading="lazy"
                    />
                  </div>
                </span>
                <article>
                  <div className={styles.footerLink}>LinkedIn</div>
                  <p>
                      linkedin.com/in/anmolvirkk
                  </p>
                </article>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.footerText}>© {year.current}, Anmol Virk</div>
     </footer>
  )
})

export default Footer