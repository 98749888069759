import React from "react";
import Header from "../components/Header";
import Projects from "../components/Home/Projects";
import Hero from "../components/Home/Hero";
import Skills from "../components/Home/Skills";
import About from '../components/Home/About'
import Footer from '../components/Footer'

const Home = React.memo(() => {
  return (
    <React.Fragment>
      <Header links={["Projects", "Technical Skills", "Soft Skills"]} />
      <Hero />
      <Projects />
      <Skills />
      <About />
      <Footer />
    </React.Fragment>
  )
})

export default Home
